import { Link } from 'react-router-dom';
import { useLastPosition } from './hooks/use-last-position';
import { PositionImageSerialList } from "./PositionImageSerialList";

export const Home = () => {
    const { lastPosition, readObject } = useLastPosition()
    
    return (
        <div className='mx-4'>
            <div className='flex flex-col justify-center text-center mb-6 space-y-8'>
                <h1 className='z-0 font-marker md:text-8xl -skew-y-6 mb-6 text-6xl'>Are you<br/>on the<br/>Waitlist?</h1>
                <p>Own your position in line.</p>
                
                <h2 className='font-marker text-4xl'>
                    {readObject.isFetched && lastPosition == 0 ? 
                        "Be the first to join the waitlist!" : 
                        `The current waitlist length is ${readObject.isFetched ? lastPosition : ''}`
                    }
                </h2>

                <p>Your position on <em>Waitlst</em> is tokenized as a NFT on an ERC-1155 contract on the Ethereum blockchain. You can buy and sell your position like any other NFT you own.</p>
                
                <div className='flex justify-center'>
                    <Link to="join" className='border-2 border-black p-3  hover:bg-black hover:text-white'>Join Waitlist</Link>
                </div>
                
                <h2 className='font-marker text-4xl'>Positions Owned</h2>
                <div className='flex justify-center'>
                    <PositionImageSerialList lastPosition={lastPosition} />
                </div>
            </div>
        </div>
    )
}